
import { defineComponent, ref, watch } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { search } from '@/guides/endpoints/wiki'
import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/inventory/Card.vue'
import SearchAdjectives from '@/components/inventory/SearchAdjectives.vue'

export default defineComponent({
  props: {
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
    adjectives: {
      type: String,
      default: '',
    },
  },
  components: {
    BackBtn,
    Card,
    SearchAdjectives,
  },
  setup(props, ctx) {
    const chromeData = ref()
    const content = ref()
    const loading = ref(false)

    const adjectivesList = ref(props.adjectives)

    chrome().then(r => {
      chromeData.value = r.data
    })

    const fetchContent = () => {
      loading.value = true

      search({
        adjectives: adjectivesList.value,
        category: props.category,
        list: props.list,
      }).then(res => {
        if (res?.data?.content?.cards) {
          content.value = res.data.content.cards
        } else {
          content.value = []
        }

        loading.value = false
      })
    }

    fetchContent()

    const reset = () => {
      console.log('reset search')
      ctx.emit('update:adjectives', null)

      console.log('adjectivesList', adjectivesList.value)
      adjectivesList.value = ''
      console.log('adjectivesList', adjectivesList.value)

      fetchContent()

      // props.adjectives = ''
      console.log('test', props.adjectives)
    }

    watch(() => props.adjectives, fetchContent)
    watch(() => props.category, fetchContent)
    watch(() => props.list, fetchContent)

    return {
      chromeData,
      content,
      adjectivesList,
      loading,
      reset,
    }
  },
})
