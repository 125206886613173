<template>
  <div class="wikisearch" v-if="chromeData">
    <BackBtn isQuali />

    <div class="wrapper mt-xl" v-if="!loading">
      <div v-if="content && content.length">
        <div class="header">
          <h2 class="title mb-xxs">Recherche pour</h2>
          <a href="#" class="header__reset" @click.prevent="reset">
            <SvgSprite class="icon" symbol="ui-reset" size="16" />
            <span>Réinitialiser</span>
          </a>
        </div>

        <div class="keywords" v-if="adjectivesList">
          <div
            class="keyword"
            v-for="(adjective, index) in adjectivesList.split(',')"
            :key="`adjective-${index}`"
          >
            {{ adjective }}
          </div>
        </div>

        <div class="mb-xs">
          <SearchAdjectives
            v-if="adjectivesList"
            route="WikiSearchByAdjectives"
            :othersAdjectives="adjectivesList"
            :chromeList="chromeData.lists.adjectives"
            :category="category"
            :placeholder="
              'Affine ta recherche en ajoutant un ou plusieurs adjectifs'
            "
            :list="list"
          />
          <SearchAdjectives
            v-else
            route="WikiSearchByAdjectives"
            :othersAdjectives="adjectivesList"
            :chromeList="chromeData.lists.adjectives"
            :category="category"
            :list="list"
          />
        </div>

        <div class="results mb-m">
          <SvgSprite symbol="ui-search" size="16" />
          {{ content.length }} articles trouvés
        </div>

        <div class="cards">
          <router-link
            class="mb-xs"
            v-for="item in content"
            :to="{ path: item.url }"
            :key="item.url"
            :href="item.url"
          >
            <Card :data="item" />
          </router-link>
        </div>
      </div>

      <div v-else class="notfound">
        <h4 class="mb-xxs">Test Oups...</h4>
        <p class="mb-xs">
          0 article correspond à ta recherche dans l'Espace de partage. Essaie
          peut-être avec un autre mot ?
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { chrome } from '@/guides/endpoints/chrome'
import { search } from '@/guides/endpoints/wiki'
import BackBtn from '@/components/BackBtn.vue'
import Card from '@/components/inventory/Card.vue'
import SearchAdjectives from '@/components/inventory/SearchAdjectives.vue'

export default defineComponent({
  props: {
    category: {
      type: String,
      default: '',
    },
    list: {
      type: String,
      default: '',
    },
    adjectives: {
      type: String,
      default: '',
    },
  },
  components: {
    BackBtn,
    Card,
    SearchAdjectives,
  },
  setup(props, ctx) {
    const chromeData = ref()
    const content = ref()
    const loading = ref(false)

    const adjectivesList = ref(props.adjectives)

    chrome().then(r => {
      chromeData.value = r.data
    })

    const fetchContent = () => {
      loading.value = true

      search({
        adjectives: adjectivesList.value,
        category: props.category,
        list: props.list,
      }).then(res => {
        if (res?.data?.content?.cards) {
          content.value = res.data.content.cards
        } else {
          content.value = []
        }

        loading.value = false
      })
    }

    fetchContent()

    const reset = () => {
      console.log('reset search')
      ctx.emit('update:adjectives', null)

      console.log('adjectivesList', adjectivesList.value)
      adjectivesList.value = ''
      console.log('adjectivesList', adjectivesList.value)

      fetchContent()

      // props.adjectives = ''
      console.log('test', props.adjectives)
    }

    watch(() => props.adjectives, fetchContent)
    watch(() => props.category, fetchContent)
    watch(() => props.list, fetchContent)

    return {
      chromeData,
      content,
      adjectivesList,
      loading,
      reset,
    }
  },
})
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__reset {
  display: flex;
  align-items: center;

  .icon {
    margin-right: 0.5rem;
  }

  color: $tangerine;
}

.keywords {
  display: flex;
  flex-wrap: wrap;
}

.keyword {
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  padding: 0.5rem 1.2rem;
  color: $regal-blue;
  font-size: 1.4rem;
  line-height: 2rem;
  background-color: white;
  border-radius: 999em;
}

.title {
  line-height: 1.3;

  span {
    color: $hippie-blue;
  }
}

.results {
  display: inline-flex;
  align-items: center;
  color: $hippie-blue;
  font-size: 12px;

  svg {
    fill: $hippie-blue;
    margin-right: 5px;
  }
}

.notfound {
  text-align: center;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;

  h4 {
    color: $rock-blue;
  }

  p {
    @include fluid(
      font-size,
      (
        xxs: 14px,
        l: 16px,
      )
    );
  }
}

::v-deep(.notfound input) {
  background: $c-white !important;
}

@include mq(xl) {
  .cards {
    display: grid;
    gap: 25px;
    grid-template-columns: repeat(2, 1fr);

    > * {
      margin: 0;
    }
  }
}

.cards {
  a {
    display: block;
  }
}
</style>
