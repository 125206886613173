<template>
  <div class="card" :class="{ 'card-picture': data.picture }">
    <g-picture
      v-if="data.picture"
      class="section__picture-outer"
      :content="data.picture"
      :key="data.label"
    />

    <div class="card-inner">
      <h4 class="mb-xxs">{{ data.title }}</h4>
      <p v-if="data.text" v-html="data.text"></p>

      <!-- Date if Ritual -->
      <div class="card__date" v-if="data.date && data.type === 'ritual'">
        <SvgSprite class="card__date__icon" symbol="ui-calendar" size="10" />
        <TimeChecker
          class="card__date__label"
          :data="{ date: data.date }"
          type="news-card"
        />
      </div>

      <SvgSprite class="icon-arrow" symbol="ui-arrow-right" size="16 16" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import TimeChecker from '@/components/TimeChecker.vue'

export default defineComponent({
  name: 'WikiCard',
  components: {
    TimeChecker,
  },
  props: {
    data: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: {} as any,
    },
  },
})
</script>

<style lang="scss" scoped>
$y: (
  xxs: 15px,
  l: 30px,
);
$x: (
  xxs: 20px,
  l: 35px,
);

.card {
  display: block;
  background: $c-white;
  position: relative;
  cursor: pointer;

  transition: transform 0.3s;

  h4,
  p {
    transition: opacity 0.3s;
  }

  &:hover {
    h4,
    p {
      opacity: 0.6;
    }

    .icon-arrow {
      transform: translateX(3px);
    }
  }

  @include mq($until: m) {
    &:hover,
    &:active {
      transform: scale(1.02);
    }
  }
}

.card-picture {
  h4 {
    margin-bottom: 0;
  }
}

.card-inner {
  @include fluid(padding-top, $x);
  @include fluid(padding-right, $y);
  @include fluid(padding-bottom, $x);
  @include fluid(padding-left, $y);

  .card-picture & {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.icon-card {
  position: absolute;
  top: $spacing / 2;
  right: $spacing;
  width: 2rem;
  height: 2rem;
}

.card__date {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;

  display: flex;
  align-items: center;

  svg {
    margin-right: 0.4rem;
  }

  .h3 {
    @include fira;

    color: $hippie-blue;
    font-size: 1.2rem;
  }
}

.card__date__icon {
  width: 1rem;
  height: 1rem;
  margin-right: $spacing / 4;
}

.card__date__label {
  display: flex;
  align-items: center;
  color: $hippie-blue;
  font-size: 1rem;
  line-height: 1.2em;

  @include mq(m) {
    font-size: 1.2rem;
  }
}

.icon-arrow {
  position: absolute;
  right: 1.5rem;
  bottom: $spacing;
  stroke: $tangerine;
  stroke-width: 2;
  transition: transform 0.3s;

  .card-picture & {
    position: relative;
    right: 0;
    bottom: 0;
  }
}

h4,
p {
  padding-right: $spacing;
}

p {
  color: $c-black;
  font-size: 1.4rem;

  @include mq(m) {
    font-size: 1.6rem;
  }
}
</style>
