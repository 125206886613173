
import { defineComponent } from 'vue'
import TimeChecker from '@/components/TimeChecker.vue'

export default defineComponent({
  name: 'WikiCard',
  components: {
    TimeChecker,
  },
  props: {
    data: {
      type: Object,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      default: {} as any,
    },
  },
})
